import { Notification, NotificationVideoCall, InterviewPoll } from '~/modals';

export interface NotificationState {
  newNotification?: Notification;
  newRequestFocus?: Notification;
  unreadNotifications?: number;
  endpoint?: string;
  newNotificationVideoCall?: NotificationVideoCall;
  newNotificationInterviewPoll?: InterviewPoll[];
  newNotificationOfForum?: Notification;
  userID?: string,
  jobApplicationId?: string,
  connected?: boolean
}

const state = (): NotificationState => ({
  newNotification: undefined,
  newRequestFocus: undefined,
  unreadNotifications: 0,
  endpoint: undefined,
  newNotificationVideoCall: undefined,
  newNotificationInterviewPoll: [],
  newNotificationOfForum: undefined,
  userID: '',
  jobApplicationId: '',
  connected: false
})

export default state
