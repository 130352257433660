import { ActionTree } from 'vuex'
import { plainToClass } from "class-transformer";
import { RootState } from 'store'
import { DepartmentState } from './state'
import {Common} from '~/modals'
import types from './types'

export interface Actions<S, R> extends ActionTree<S, R> {}

const actions: Actions<DepartmentState, RootState> = {
  async fetchList({commit, state}) {
    try{
      if(state.departments.length != 0) return;
      let results: Object[] = await this.$axios.$get("/api/v1/departments");
      const list = plainToClass(Common, results["data"]);
      commit(types.FETCH_LIST, list)
    } catch(error){
      // TODO : handle Error
    }
  }
}

export default actions
