import UserInfo from '~/modals/user_info'

export interface UserState {
  userInfo: UserInfo;
}

const state = (): UserState => ({
  userInfo: {}
})

export default state
