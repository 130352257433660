const regex = /(?:\b(MS)?IE\s+|\bTrident\/7\.0;.*\s+rv:)(\d+)/g;
export default (context, inject) => {
  const userAgent = process.server ? context.req.headers['user-agent'] : navigator.userAgent;
  const isIE = regex.test(userAgent);
  const isFirefox = userAgent.toLowerCase().indexOf('firefox') > -1;
  if(process.client) {
    const isiOS = [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(userAgent.platform)
    // iPad on iOS 13 detection
    || (userAgent.includes("Mac") && "ontouchend" in document);
    inject('isiOS', isiOS);
  }
  inject('isIE', isIE);
  inject('isFirefox', isFirefox);
}