import Vue from 'vue'
import VScrollLock from 'v-scroll-lock'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

Vue.use(VScrollLock,{
    // disableBodyScroll,
    // enableBodyScroll
    bodyScrollOptions: {
      reserveScrollBarGap: true,
    }
});