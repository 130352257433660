import { MutationTree } from 'vuex'
import { TagInterest } from './state'
import types from './types'

const mutations: MutationTree<TagInterest> = {
  [types.SET_INTERESTE_TAGS](state, tagIDs) {
    const idx = state.tagInterestIDs.indexOf(tagIDs);
		if (idx >= 0) {
			state.tagInterestIDs.splice(idx, 1);
		} else {
			state.tagInterestIDs.push(tagIDs)
		}
  },
  [types.RESET_INTERESTE_TAGS](state) {
    state.tagInterestIDs = [];
  }
}

export default mutations