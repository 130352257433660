import { ActionTree } from "vuex";
import { plainToClass } from "class-transformer";
import { RootState } from "store";
import { CountryState } from "./state";
import { Common } from "~/modals";
import types from "./types";

export interface Actions<S, R> extends ActionTree<S, R> {}

const actions: Actions<CountryState, RootState> = {
  async fetchCountries({ commit, state }) {
    try {
      let results: Object = await this.$axios.$get(`/api/v1/countries`);
      let countries: Common[] = [];
      let tmpArr = [];
      tmpArr = results["data"];
      countries = plainToClass(Common, tmpArr);
      commit(types.SET_COUNTRIES, countries);
    } catch (error) {
      // TODO : handle Error
    }
  }
};

export default actions;
