import { MutationTree } from 'vuex'
import { CandidateState } from './state'
import types from './types'
import { Candidate } from '~/modals'

const mutations: MutationTree<CandidateState> = {
  [types.SET_CANDIDATE](state, candidate: Candidate) {
    state.candidate = candidate
  },
  [types.SET_CANDIDATE_ID](state, candidateID: string) {
    state.candidateID = candidateID
  },
  [types.SET_CANDIDATES](state, candidates: Candidate[]) {
    state.candidates = candidates
  }
}

export default mutations
