import { ActionTree } from "vuex";
import { plainToClass } from "class-transformer";
import { RootState } from "store";
import { ProfileState } from "./state";
import { Profile } from "~/modals";
import types from "./types";

export interface Actions<S, R> extends ActionTree<S, R> {}

const actions: Actions<ProfileState, RootState> = {
  addProfileIDToQueue({ commit }, profileIDs: string|Array<string>) {
    if (profileIDs) {
      commit(types.ADD_PROFILE_ID_TO_QUEUE, profileIDs);
    }
  },
  async fetchNewProfiles({ commit, state }) {
    try {
      if (state.queueIDs.length === 0) return;
      const tempIDs: string[] = [...state.queueIDs];
      let _url = `/api/v1/user/profile?ids=${tempIDs.join(",")}`;
      if (!this.$auth.loggedIn) {
        _url = _url + `&language=${(this as any).$i18n.locale}`
      }
      let results: Object = await this.$axios.$get(_url);
      const newProfiles = plainToClass(Profile, results["data"] as []);
      newProfiles.forEach(o => {
        !o.avatar && (o.avatar = "/img/avatar_anonymous.jpg");
      });
      commit(types.ADD_TO_PROFILE, newProfiles);
      commit(types.ADD_QUEUE_IDS_TO_FETCH_LIST);
      commit(types.CLEAR_QUEUE);
    } catch (error) {
      // TODO : handle Error
    }
  },
  async fetchNewProfileById({ commit, state }, userId) {
    try {
      if (!userId) return new Profile();
      const user = state.profiles.find(_o => _o.userID === userId);
      let _url = `/api/v1/user/profile?ids=${userId}`;
      if (!this.$auth.loggedIn) {
        _url = _url + `&language=${(this as any).$i18n.locale}`
      }
      const results = await this.$axios.$get(_url);
      const newProfiles = plainToClass(Profile, results["data"] as []);
      newProfiles.forEach(o => {
        !o.avatar && (o.avatar = '/img/avatar_anonymous.jpg');
      });
      if(user){
        const listUser = state.profiles.filter(_o => _o.userID !== userId);
        commit(types.CLEAR_PROFILE);
        commit(types.ADD_TO_PROFILE, [...listUser, ...newProfiles])
      }
      else{
        commit(types.ADD_TO_PROFILE, newProfiles);
      }

      return newProfiles?.[0] ?? new Profile();
    } catch (error) {
      // TODO : handle Error
      return new Profile();
    }
  },
  clearToProfile({ commit, state }) {
    commit(types.CLEAR_PROFILE);
    commit(types.CLEAR_FETCH_ID);
    commit(types.CLEAR_QUEUE);
  },
  findAndReplace({ commit, state }, {propertyId, _name, _avatar}) {
    commit(types.REPLACE_PROFILE, {propertyId, _name, _avatar});
  }
};

export default actions;
