import { GetterTree } from 'vuex'
import { RootState } from 'store'
import { TagInterest } from './state'

const getters: GetterTree<TagInterest, RootState> = {
  getTagInterestIDs: (state) => {
    let listTags: String[] = new Array<String>(state.tagInterestIDs.length);
    listTags = state.tagInterestIDs;
    return [...listTags];
  },
}
export default getters