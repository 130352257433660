
export interface LoadingState {
  loading: boolean;
  showConfirm: boolean
}

const state = (): LoadingState => ({
  loading: false,
  showConfirm: false
})

export default state
