import { ActionTree, ActionContext } from "vuex";
import { RootState } from "store";
import { ReportState } from "./state";
import types, {GENERAL_TYPE} from "./types";
import { plainToClass } from 'class-transformer';
import { Report, AddReport } from "~/modals";

function transformArray(source: Report[], lang) {
  source.forEach(_o => {
    _o.name = _o.multipleNames?.find(_o => _o.languageCode === lang);
  })
}

export interface Actions<S, R> extends ActionTree<S, R> {}

const actions: Actions<ReportState, RootState> = {
  async getReportByType({ commit, state }) {
    try {
      const rs = await this.$axios.$get('/api/v1/report_items/general', 
      { params: {
        type: state.typeReport
      }});
      const reports = plainToClass(Report, rs['data'] as []);
      const language = (this.$auth.user as any)?.support_language?.code ?? '';
      transformArray(reports, language);
      commit(types.GET_GENERAL_REPORT, reports);
    } catch (error) {
      throw error;
    }
  },
  async getSpecificById({ commit }, id: string) {
    try {
      // commit(types.GET_SPECIFIC_BY_ID, []);
      const rs = await this.$axios.$get('/api/v1/report_items/specific/' + id);
      const reports = plainToClass(Report, rs['data'] as []);
      const language = (this.$auth.user as any)?.support_language?.code ?? '';
      transformArray(reports, language);
      commit(types.GET_SPECIFIC_BY_ID, reports);
    } catch (error) {
      throw error;
    }
  },
  async sendReport({commit, state}, report: AddReport) {
    let isDelete = false;
    try {
      const rs = await this.$axios.$post('/api/v1/reports', {
        "report_item_id": report.reportItemId,
        "objectable_id": state.reportFunctionId,
        "objectable_type": state.typeReport,
        "content": report.content
      });
      try {
        if(state.typeReport === 'COMMUNITY') {
          const rs = await this.$axios.$get(`api/v1/communities/${state.reportFunctionId}`);
        } else if (state.typeReport === 'BLOG') {
          const rs = await this.$axios.$get(`api/v1/blogs/${state.reportFunctionId}`);
        }
      } catch (error) {
        isDelete = true;
        if(state.typeReport === 'COMMUNITY') {
          commit(types.REMOVE_QUESTION);
        }
      }
      return { status: rs.status === 'success', isDelete: isDelete, type: state.typeReport};
    } catch (error) {
      throw error;
    }

  },
  async getSpecificsType({ commit, state }) {
    try {
      commit(types.GET_SPECIFIC_BY_ID, []);
      const rs = await this.$axios.$get('/api/v1/report_items/specific', {
        params: {
          type: state.typeReport
        }
      });
      const reports = plainToClass(Report, rs['data'] as []);
      const language = (this.$auth.user as any)?.support_language?.code ?? '';
      transformArray(reports, language);
      commit(types.GET_SPECIFICS, reports);
    } catch (error) {
      throw error;
    }
  },
  showReport({commit}, type: GENERAL_TYPE) {
    commit(types.SHOW_REPORT, type);
  },
  hideReport({commit}) {
    commit(types.HIDE_REPORT);
  },
  setType({commit}, _type: GENERAL_TYPE) {
    commit(types.SET_TYPE, _type);
  },
  setReportAllType({commit, state}) {
    let countFetch = 0;
    return new Promise((resolve, reject) => {
      Object.keys(state.reportAllTypes).forEach(async (_key) => {
        try {
          if (Array.isArray(state.reportAllTypes[_key]) && state.reportAllTypes[_key].length) {
            countFetch++;
            if (countFetch === Object.keys(state.reportAllTypes).length) {
              resolve(true);
            }
            return;
          }
          const rs = await this.$axios.$get('/api/v1/report_items/general', {
            params: {
              type: _key
            }
          });
          const reports = plainToClass(Report, rs['data'] as []);
          const language = (this.$auth.user as any)?.support_language?.code ?? '';
          transformArray(reports, language);
          commit(types.SET_REPORT_ALL_TYPE, [_key, reports]);
          countFetch++;
          if (countFetch === Object.keys(state.reportAllTypes).length) {
            resolve(true);
          }
        } catch (error) {
          reject(error)
        }
      });
    });
  },
  setSpecificAllType({commit, state}) {
    let countFetch = 0;
    return new Promise((resolve, reject) => {
      Object.keys(state.specificAllTypes).forEach(async (_key) => {
        try {
          if (Array.isArray(state.specificAllTypes[_key]) && state.specificAllTypes[_key].length) {
            countFetch++;
            if (countFetch === Object.keys(state.specificAllTypes).length) {
              resolve(true);
            }
            return;
          }
          const rs = await this.$axios.$get('/api/v1/report_items/specific', {
            params: {
              type: _key
            }
          });
          const reports = plainToClass(Report, rs['data'] as []);
          const language = (this.$auth.user as any)?.support_language?.code ?? '';
          transformArray(reports, language);
          commit(types.SET_SPECIFIC_ALL_TYPE, [_key, reports]);
          countFetch++;
          if (countFetch === Object.keys(state.specificAllTypes).length) {
            resolve(true);
          }
        } catch (error) {
          reject(error)
        }
      });
    });
  },
  removeQuestion({commit}) {
    commit(types.REMOVE_QUESTION);
  }
};

export default actions;
