import {Common} from '~/modals'

export interface CategoryHashtagState {
  categoryHashtags: Common[];
}

const state = (): CategoryHashtagState => ({
  categoryHashtags: []
})

export default state
