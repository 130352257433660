import { ActionTree, ActionContext } from "vuex";
import { RootState } from "store";
import { LoadingState } from "./state";
import types from "./types";

export interface Actions<S, R> extends ActionTree<S, R> {
  setLoading(context: ActionContext<S, R>, loading: boolean): void;
  setShowConfirm(context: ActionContext<S, R>, showConfirm: boolean): void;
}

const actions: Actions<LoadingState, RootState> = {
  setLoading({ commit }, loading: boolean) {
    commit(types.SET_LOADING, loading)
  },
  setShowConfirm({ commit }, showConfirm: boolean) {
    commit(types.SET_SHOW_CONFIRM, showConfirm)
  }
};

export default actions;
