import {Common} from '~/modals'

export interface CategoryState {
  categories: Common[];
  newsCategories: Common[];
}

const state = (): CategoryState => ({
  categories: [],
  newsCategories: []
})

export default state
