

const handleHover = (id, name, line, fontSize, padding = 0, idTimeElement = null, isMobile = false) =>
{
  try{
    return evaluteText(id, line, name, fontSize, padding, idTimeElement) && !isMobile ? name : '';
  }catch(e){
  }
}

const evaluteText = (id, line, str, fontSize, padding = 0, idTimeElement): boolean => {
  const box = document.getElementById(id) as HTMLElement | null;
  const elementTime = document.getElementById(idTimeElement) as HTMLElement | null;
  if (box) {
    if (line == 1) {
      return box!.offsetWidth < box!.scrollWidth;
    }
    const oldWidth = box.offsetWidth;
    let box2 = box.cloneNode(true);
    document.body.appendChild(box2);
    box2.style.whiteSpace = 'nowrap';
    box2.style.visibility = 'hidden';
    box2.style.position = 'fixed';
    box2.style.zIndex = '-1';
    const isEllipsis = box2!.scrollWidth > oldWidth * line
    document.body.removeChild(box2);
    return isEllipsis;
    // console.log(box)
    // console.log(elementTime)
    var offsetWidth: any = 0;
    var offsetWidthTimeElement: any = 0;
    offsetWidthTimeElement = elementTime?.offsetWidth ?? 0;
    // console.log(offsetWidthTimeElement);
    offsetWidth = box?.offsetWidth - padding * 2 - offsetWidthTimeElement;
    // console.log("offset", offsetWidth);
    // console.log('handleString', handleString(str, fontSize));
    if(offsetWidth > 0){
      if (line == 2) {
        if (handleString(str, fontSize) > offsetWidth * 2) {
          return true;
        } else {
          return false;
        }
      } else if (line == 1) {
          if (handleString(str, fontSize) > (offsetWidth)) {
            return true;
          } else {
            return false;
          }
      }
    }
  }
  return false;
};


const handleString = (str, fontSize) => {
  const widths = [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0.2796875, 0.2765625, 0.3546875, 0.5546875, 0.5546875,
    0.8890625, 0.665625, 0.190625, 0.3328125, 0.3328125, 0.3890625, 0.5828125,
    0.2765625, 0.3328125, 0.2765625, 0.3015625, 0.5546875, 0.5546875, 0.5546875,
    0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875,
    0.2765625, 0.2765625, 0.584375, 0.5828125, 0.584375, 0.5546875, 1.0140625,
    0.665625, 0.665625, 0.721875, 0.721875, 0.665625, 0.609375, 0.7765625,
    0.721875, 0.2765625, 0.5, 0.665625, 0.5546875, 0.8328125, 0.721875,
    0.7765625, 0.665625, 0.7765625, 0.721875, 0.665625, 0.609375, 0.721875,
    0.665625, 0.94375, 0.665625, 0.665625, 0.609375, 0.2765625, 0.3546875,
    0.2765625, 0.4765625, 0.5546875, 0.3328125, 0.5546875, 0.5546875, 0.5,
    0.5546875, 0.5546875, 0.2765625, 0.5546875, 0.5546875, 0.221875, 0.240625,
    0.5, 0.221875, 0.8328125, 0.5546875, 0.5546875, 0.5546875, 0.5546875,
    0.3328125, 0.5, 0.2765625, 0.5546875, 0.5, 0.721875, 0.5, 0.5, 0.5,
    0.3546875, 0.259375, 0.353125, 0.5890625,
  ];
  const avg = 0.5279276315789471;

  return (
    str
      .split("")
      .map((c) =>
        c.charCodeAt(0) < widths.length ? widths[c.charCodeAt(0)] : avg
      )
      .reduce((cur, acc) => acc + cur) * fontSize * 1.05 //sai số ~ 5% tùy font-weight
  );
};

export default ({ app }, inject) => {
  // Inject $hello(msg) in Vue, context and store.
  inject('handleHover', handleHover);
  inject('handleString', handleString);
  inject('evaluteText', evaluteText);
}