import { ActionTree } from 'vuex'
import { plainToClass } from "class-transformer";
import { RootState } from 'store'

import { CategoryState } from './state'
import {Common} from '~/modals'
import types from './types'

export interface Actions<S, R> extends ActionTree<S, R> {}

const actions: Actions<CategoryState, RootState> = {
  async fetchList({commit, state}) { 
    try{
      if(state.categories.length != 0) return;
      let results: Object[] = [];
      if(this.$auth.loggedIn) {
        results = await this.$axios.$get("/api/v1/categories");
      } else {
        results = await this.$axios.$get(`/api/v1/categories?language_code=${(this as any).$i18n.locale}`);
      }
      const list = plainToClass(Common, results["data"]);
      commit(types.FETCH_LIST, list)
    } catch(error){
      // TODO : handle Error
    }
  },
  clearCategory({ commit, state }) {
    commit(types.FETCH_LIST, []);
  },
  async fetchListNewsCategory({commit, state}) { 
    try{
      if(state.newsCategories.length != 0) return;
      let results: Object[] = [];
      if(this.$auth.loggedIn) {
        results = await this.$axios.$get("/api/v1/news/categories");
      } else {
        results = await this.$axios.$get(`/api/v1/news/categories?language_code=${(this as any).$i18n.locale}`);
      }
      const list = plainToClass(Common, results["data"]);
      commit(types.FETCH_LIST_NEWS_CATEGORY, list)
    } catch(error){
      // TODO : handle Error
    }
  },
  clearNewsCategory({ commit, state }) {
    commit(types.FETCH_LIST_NEWS_CATEGORY, []);
  },
}

export default actions
