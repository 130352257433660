import Vue from 'vue'
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: '/img/image-default-load.jpg',
    loading: '/img/image-default-load.jpg',
    attempt: 3,
    listenEvents: [ 'scroll', 'animationend', 'transitionend' ],
    lazyComponent: true
})
