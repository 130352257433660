import {Profile} from '~/modals'

export interface ProfileState {
  profiles: Profile[];
  fetchedIDs: string[];
  queueIDs: string[];
}

const state = (): ProfileState => ({
  profiles: [],
  fetchedIDs: [],
  queueIDs: [],
})

export default state
