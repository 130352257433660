export default ({ app }) => {
	app.router.options.scrollBehavior = (to, from, savedPosition) => {
		// if (to.matched.some((r) => r.components.default['options'].scrollToTop)) {
		// 	return {x: 0, y: 0}
		// }
		// if ((to.name as string).includes('candidates-slug') && to.name !== from.name) {
		// 	return {x: 0, y: 0}
		// }
		if (savedPosition) {
		    return new Promise((resolve, reject) => {
				setTimeout(() => {
				  resolve(savedPosition)
				},10)
			  })
		}
		// if (savedPosition) {
		// 	return savedPosition;
		// }
		// if (to.hash) {
		// 	return {selector: to.hash}
		// } else {
		// 	return {x: 0, y: 0}
		// }
		// if (!(to.query.page) && (to.name !== from.name || to.path !== from.path)) {
		// 	return {x: 0, y: 0}
		// }
		if (to.name == from.name && to.path == from.path) {
			if (to.fullPath == from.fullPath) {
				return {x: 0, y: 0, behavior: 'smooth'}
			}
			return;
		}
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve({x: 0, y: 0})
			},100)
		})
	}
}