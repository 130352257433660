export default (context, inject) => {
  const handleErrorApi = (error, callback) => {
    let _error ='';
    if (!error.response) {
      _error ='ERROR_ERR_NETWORK_DISCONNECTED';
    } else {
      _error = error.response?.data?.error?.code ?? 'ERR_SER0101';
    }
    // _error = _error.substring(_error.indexOf('.') + 1, _error.length);
      _error = _error.replace('.', '_');
    if (typeof callback == 'function') {
      callback(_error);
    }
  }
  // Inject $handleErrorApi() in Vue, context and store.
  inject('handleErrorApi', handleErrorApi);
  context.$handleErrorApi = handleErrorApi;
}