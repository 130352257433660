import { MutationTree } from 'vuex'
import { NavState } from './state'
import types from './types'

const mutations: MutationTree<NavState> = {
  [types.SET_WIDTH_POPUP](state, width) {
    state.widthPopup = width;
  }
}

export default mutations
