import { MutationTree } from 'vuex'
import { ModalDeletedAccount } from './state'
import types from './types'

const mutations: MutationTree<ModalDeletedAccount> = {
  [types.NOTIFY_ACCOUNT_DELETED_MODAL](state, isVisible) {
    state.isVisible = isVisible
  }
}

export default mutations