import { ActionTree } from 'vuex'
import { plainToClass } from "class-transformer";
import { RootState } from 'store'

import { CategoryHashtagState } from './state'
import {Common} from '~/modals'
import types from './types'
import { Vue, Component, Prop } from "nuxt-property-decorator";

export interface Actions<S, R> extends ActionTree<S, R> {}

const actions: Actions<CategoryHashtagState, RootState> = {
  async fetchList({commit, state}) { 
    try{
      if(state.categoryHashtags.length != 0) return;
      let results: Object[] = []
      if(this.$auth.loggedIn) {
        results = await this.$axios.$get("/api/v1/hashtags");
      } else {
        results = await this.$axios.$get(`/api/v1/hashtags?language=${(this as any).$i18n.locale}`);
      }
      const list = plainToClass(Common, results["data"]);
      commit(types.FETCH_LIST, list)
    } catch(error){
      // TODO : handle Error
    }
  },
  clearCategoryHashtag({ commit, state }) {
    commit(types.FETCH_LIST, []);
  },
}

export default actions
