// Importing dictionaries for vee-validate
import ja from 'vee-validate/dist/locale/ja'
import vi from 'vee-validate/dist/locale/vi'
import pt from 'vee-validate/dist/locale/pt_BR'
import { Validator } from 'vee-validate'
import flatpickr from 'flatpickr'
import { Japanese } from 'flatpickr/dist/l10n/ja.js'
import { Vietnamese } from 'flatpickr/dist/l10n/vn.js'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import { english } from 'flatpickr/dist/l10n/default'

export default function ({ app }) {
  //set locale for moment
  app.$moment.locale(app.i18n.locale);
  // Loading languages for Vee
  Validator.localize('ja', {
    messages: ja.messages
  })
  Validator.localize('vi', {
    messages: vi.messages
  })
  Validator.localize('pt', {
    messages: pt.messages
  })

  Vietnamese.rangeSeparator = ' đến '
  Japanese.rangeSeparator = ' >> '
  // Localizing the app whja user reviesh or access a localized link
  // Validator.localize(app.i18n.loadedLanguages[0])
  Validator.localize(app.i18n._vm.locale) //fix bug hiển thị language cho flatpickr
  switch (app.i18n._vm.locale) {
    case 'ja':
      flatpickr.localize(Japanese)
      break
    case 'vi':
      flatpickr.localize(Vietnamese)
      break
    case 'pt':
      flatpickr.localize(Portuguese)
      break
    default:
      flatpickr.localize(english)
      break
  }

  app.i18n.onLanguageSwitched = (oldLocale, newLocale) => {
    Validator.localize(newLocale);
    app.$moment.locale(newLocale);
    switch (newLocale) {
      case 'ja':
        flatpickr.localize(Japanese)
        break
      case 'vi':
        flatpickr.localize(Vietnamese)
        break
      case 'pt':
        flatpickr.localize(Portuguese)
        break
      default:
        flatpickr.localize(english)
        break  
    }
  }
  // Called everytime language change
  app.i18n.beforeLanguageSwitch = (oldLocale, newLocale) => {
    // flatpickr.localize(flatpickr.l10ns.en);
  }
}
