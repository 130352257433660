import { ActionTree } from 'vuex'
import { RootState } from 'store'
import { ModalDeletedAccount } from './state'
import types from './types'

export interface Actions<S, R> extends ActionTree<S, R> {}

const actions: Actions<ModalDeletedAccount, RootState> = {
  changeShowModal({commit, state}, isVisible) { 
    try{
      commit(types.NOTIFY_ACCOUNT_DELETED_MODAL, isVisible)
    } catch(error){
      // TODO : handle Error
    }
  }
}

export default actions