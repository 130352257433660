import { MutationTree } from 'vuex'
import {Common} from '~/modals'
import { TagState } from './state'
import types from './types'

const mutations: MutationTree<TagState> = {
  [types.FETCH_LIST](state, tags: Common[]) {
    state.tags = tags
  },
  [types.GET_GENERAL_TAG](state, tags: Common[]) {
    state.generalTags = tags
  },
  [types.GET_HASH_TAG](state, tags: Common[]) {
    state.hashTags = tags
  }
}

export default mutations
