import {Country} from '~/modals'

export interface CountryState {
  countries: Country[];
}

const state = (): CountryState => ({
  countries: [],
})

export default state
