
export interface MessageState {
  message: string;
}

const state = (): MessageState => ({
  message: ""
})

export default state
