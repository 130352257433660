// import News from '~/modals/news'

export default (context, inject) => {
  // Inject $hello(msg) in Vue, context and store.
  const goToDetailNews = (news) => {
    return context.app.localePath({
      name: "news-slug",
      params: { slug: context.app.$formatSlugUrl(news.title || "", context.app.$fromUUID(news.id || "")) },
      query: { language: context.app.$auth.loggedIn ? context.app.$auth.user.support_language.code : '' }
    });
  }
  inject('goToDetailNews', goToDetailNews);
  context.$goToDetailNews = goToDetailNews;

  const goToCategory = (nextHash) => {
    return context.app.localePath({
      name: "news",
      hash: "#" + nextHash
    })
  }
  inject('goToCategory', goToCategory);
  context.$goToCategory = goToCategory;

  const goToDetailCandidate = (nameUser, idUser) => {
    return context.app.localePath({
      name: 'other-candidates-slug',
      params: { slug: context.app.$formatSlugUrl(nameUser, context.app.$fromUUID(idUser))}
    })
  }

  inject('goToDetailCandidate', goToDetailCandidate);
  context.$goToDetailCandidate = goToDetailCandidate;
  
}