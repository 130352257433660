
import { createPopper, Placement } from "@popperjs/core";

const redirectUrl = ($auth, localePath, $formatSlugUrl, $fromUUID, locale, to) => {
  // console.log("on utils redirect ", localePath, $formatSlugUrl, $fromUUID, locale, to);
  if (!$auth.loggedIn) {
    return localePath(to);
  } else {
    if ($auth.user?.role === 'USER') {
      return localePath('/roles', locale)
    }
    if($auth.user?.role === 'CANDIDATE' && !$auth.user?.support_language) {
      return localePath('/countries', locale)
    } else if ($auth.user?.role === 'CANDIDATE' && $auth.user?.support_language === 'ja') {
      return localePath('/forums', $auth.user?.setting_language?.code ?? locale);
    }
  }
  return localePath('/posts', $auth.user?.setting_language?.code ?? locale);
}
const changePositionPopup = (_el, elPopup, _placement: Placement = "top", options = {}) => {
  const popper = createPopper(_el, elPopup, {
    placement: _placement,
    modifiers: [
      {
        name: "offset",
        options: {
          offset: (options as any).offset || [0, -1]
        }
      },
      {
        name: "toggleClass",
        enabled: true,
        phase: "write",
        fn({ state }) {
          _el.classList.toggle(
            "drop-" + _placement,
            state.placement === _placement
          );
        }
      }
    ]
  });
  return () => popper.destroy();
}

export { redirectUrl, changePositionPopup };