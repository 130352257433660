import { MutationTree } from 'vuex'
import { UserState } from './state'
import UserInfo from '~/modals/user_info'
import types from './types'

const mutations: MutationTree<UserState> = {
  [types.SET_USER_INFO](state, userInfo: UserInfo) {
    state.userInfo = userInfo
  }
}

export default mutations
