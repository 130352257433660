const types = {
  SET_END_POINT: 'SET_END_POINT',
  SET_NEW_NOTIFICATION: 'SET_NEW_NOTIFICATION',
  SET_UNREAD_NOTIFICATIONS: 'SET_UNREAD_NOTIFICATIONS',
  SET_NEW_REQUEST_FOCUS: 'SET_NEW_REQUEST_FOCUS',
  SET_SUBSCRIBE_VIDEO_CALL: 'SET_SUBSCRIBE_VIDEO_CALL',
  SET_SUBSCRIBE_INTERVIEW_POLL: 'SET_SUBSCRIBE_INTERVIEW_POLL',
  SET_USER_ID: 'SET_USER_ID',
  SET_JOB_APPLICATION_ID: 'SET_JOB_APPLICATION_ID',
  SET_CONNECTED: 'SET_CONNECTED',
  SET_NEW_NOTIFICATION_OF_FORUM: 'SET_NEW_NOTIFICATION_OF_FORUM'
}

export default types
