import { MutationTree } from "vuex";
import { Profile } from "~/modals";
import { ProfileState } from "./state";
import types from "./types";

const mutations: MutationTree<ProfileState> = {
  [types.ADD_PROFILE_ID_TO_QUEUE](state, profileID: string|Array<string>) {
    if (Array.isArray(profileID) && profileID.length) {
      const fetchIds = state.fetchedIDs.filter(o => profileID.some(id => id === o));
      const queueIds = state.queueIDs.filter(o => profileID.some(id => id === o));
      if (!fetchIds.length && !queueIds.length) {
        state.queueIDs.push(...profileID);
      }
      return;
    }
    if (
      // not in fetched list
      state.fetchedIDs.indexOf(profileID as string) === -1 &&
      // not in waiting list
      state.queueIDs.indexOf(profileID as string) === -1
    ) {
      state.queueIDs.push(profileID as string);
    }
  },
  [types.ADD_QUEUE_IDS_TO_FETCH_LIST](state, profileID: string) {
    state.queueIDs.forEach(profileID => {
      // not in fetched list
      if (state.fetchedIDs.indexOf(profileID) === -1) {
        state.fetchedIDs.push(profileID);
      }
    });
  },
  [types.ADD_TO_PROFILE](state, newProfiles: Profile[]) {
    state.profiles = [...state.profiles, ...newProfiles];
  },
  [types.CLEAR_PROFILE](state) {
    state.profiles = [];
  },
  [types.CLEAR_QUEUE](state) {
    state.queueIDs = [];
  },
  [types.CLEAR_FETCH_ID](state) {
    state.fetchedIDs = [];
  },
  [types.REPLACE_PROFILE](state, {propertyId, _name, _avatar}) {
    const idx = state.profiles.findIndex(o => o.propertyID === propertyId);
    if(idx >= 0) {
      
      if (_avatar) {
        state.profiles.splice(idx, 1, {...state.profiles[idx], name: _name, avatar: _avatar});
      } else state.profiles.splice(idx, 1, {...state.profiles[idx], name: _name});
    }
    // idx >= 0 && state.profiles.splice(idx, 1, {...state.profiles[idx], name: _name, avatar: _avatar});
  }
};

export default mutations;
