import {Common} from '~/modals'

export interface DepartmentState {
  departments: Common[];
}

const state = (): DepartmentState => ({
  departments: []
})

export default state
