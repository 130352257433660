import { Common } from '~/modals';

const filteredArrayLanguageCode = (_arrayInput: Common[], languageCode: string) => {
  try {
    const copy = [..._arrayInput];
    return copy.map(rs => {
      if (rs?.names?.length) {
        rs.names = rs?.names.filter(_o => _o.language_code === languageCode);
      } else {
        rs?.names?.push({id: '', name: ''});
      }
      rs.name = rs?.names![0]?.name;
      (rs as any).language_code = rs?.names![0]?.language_code;
      return rs;
    });
  } catch (error) {
    return Array<Common>(0);
  }
}
const findArray = (_arrayInput: Common[], languageCode: string, id: string) => {
  try {
    const copy = [..._arrayInput];
    // const inputs = filteredArrayLanguageCode(_arrayInput, languageCode);
    return copy.find(_o => _o.id == id)?.names?.find(o => o.language_code === languageCode) ?? new Common();
  } catch (error) {
    return new Common();
  }
}

export {
  findArray
}