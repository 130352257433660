import { ActionTree } from 'vuex'
import { RootState } from 'store'
import { TagInterest } from './state'
import types from './types'

export interface Actions<S, R> extends ActionTree<S, R> {}

const actions: Actions<TagInterest, RootState> = {
  setTagInterest({commit, state}, tagIDs) { 
    try{
      commit(types.SET_INTERESTE_TAGS, tagIDs)
    } catch(error){
      // TODO : handle Error
    }
  },

  resetTagInterest({commit, state}) { 
    try{
      commit(types.RESET_INTERESTE_TAGS)
    } catch(error){
      // TODO : handle Error
    }
  }
}

export default actions