import { MutationTree } from 'vuex'
import { ReportState } from './state'
import types, {GENERAL_TYPE, GENERAL_TYPES} from './types'

const mutations: MutationTree<ReportState> = {
  [types.GET_GENERAL_REPORT](state, reports) {
    state.reports = reports;
  },
  [types.GET_SPECIFIC_BY_ID](state, specificReports) {
    state.specificReports = specificReports;
  },
  [types.SHOW_REPORT](state, args) {
    state.showReport = true;
    state.typeReport = args[0];
    state.reportFunctionId = (args as any)[1] || '';
  },
  [types.HIDE_REPORT](state) {
    state.showReport = false;
    state.typeReport = '';
    state.reports = [];
    state.specificReports = [];
  },
  [types.SET_TYPE](state, _type: GENERAL_TYPE) {
    state.typeReport = _type;
  },
  [types.GET_SPECIFICS](state, specificTypes) {
    state.specificTypes = specificTypes;
  },
  [types.SET_REPORT_ALL_TYPE](state, payload) {
    const [key, data] = payload;
    state.reportAllTypes[key] = data;
  },
  [types.SET_SPECIFIC_ALL_TYPE](state, payload) {
    const [key, data] = payload;
    state.specificAllTypes[key] = data;
  },
  [types.REMOVE_QUESTION](state) {
    state.removeQuestion = state.reportFunctionId;
  },
}

export default mutations
