import { ActionTree } from "vuex";
import { RootState } from "store";
import { NavState } from "./state";
import types from "./types";

export interface Actions<S, R> extends ActionTree<S, R> {}

const actions: Actions<NavState, RootState> = {
  setWidth({commit}, width) {
    commit(types.SET_WIDTH_POPUP, width);
  }
};

export default actions;
