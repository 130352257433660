import { ActionTree, ActionContext } from "vuex";
import { RootState } from "store";
import { MessageState } from "./state";
import types from "./types";

export interface Actions<S, R> extends ActionTree<S, R> {
  setMessage(context: ActionContext<S, R>, message: string): void;
}

const actions: Actions<MessageState, RootState> = {
  setMessage({ commit }, message: string) {
    commit(types.SET_MESSAGE, message)
  }
};

export default actions;
