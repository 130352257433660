import { ActionTree } from "vuex";
import { RootState } from "store";
import { plainToClass } from "class-transformer";
import { Client } from "@stomp/stompjs";
import * as SockJS from 'sockjs-client';
import { NotificationState } from "./state";
import types from "./types";
import { Notification, EventCancel } from "@/modals";

let client: Client | undefined;
let connected: boolean = false;
let intervalFetchEndPoint: NodeJS.Timeout | undefined;
let isSubscribe = false;

const MY_SUB_ID = 'my-subscription-id';

export interface Actions<S, R> extends ActionTree<S, R> {}

const actions: Actions<NotificationState, RootState> = {
  async subscribe({ commit, state }) {
    try {   
      const result: Object = await this.$axios.$get(`/api/v1/community/notifications/endpoint`);
      const endpoint: string = result["data"];
      commit(types.SET_END_POINT, endpoint);
      commit(types.SET_USER_ID, this.$auth.user?.id);
      client = await createStompClient();
      client.onConnect = async (frame) => {
        client?.subscribe(`/queue/${state.endpoint}`, (newContent) => {
          isSubscribe = true;
          //headers subscribe
          const { headers } = newContent;
          const message = JSON.parse(newContent.body);
          const newNotification: Notification = plainToClass(Notification, message['data']);
          newNotification.messageID = newNotification.messageID ? newNotification.messageID : (newNotification['message'] ? newNotification['message']['id'] : newNotification.id);
          (newNotification as any).senderIDs = (newNotification as any).senderIDs || [];
          newNotification.senderID = newNotification.senderID || '';
          if(newNotification.message) newNotification.parent = newNotification.message['parent'];
          newNotification.conversationID = newNotification.conversationID ? newNotification.conversationID: (newNotification.message ? newNotification.message.conversation_id : '');
          newNotification.createdAt = (new Date()).getTime().toString();
          newNotification.message?.event && (newNotification.event = plainToClass(EventCancel, newNotification.message?.event));
          if (newNotification.notificationType === 'COMMUNITY') {
            commit(types.SET_NEW_NOTIFICATION_OF_FORUM, Object.assign({}, newNotification));
          } else {
            commit(types.SET_NEW_NOTIFICATION, newNotification);
          }
          newContent.ack(headers);
        }, {
          'id': MY_SUB_ID + '-' + (state.endpoint || ''),
          'durable': 'false',
          'exclusive': 'false',
          'ack': 'client',
          'auto-delete': 'false',
          //'x-expires': 60000
        });
      }
      // client.onDisconnect = () => {
      //   // TODO handle
      //   if (process.env.NODE_ENV === 'development') {
      //   }
      //   isSubscribe = false;
      // };
      // client.onStompError = event => {
      //   // TODO handle
      //   if (process.env.NODE_ENV === 'development') {
      //   }
      //   isSubscribe = false;
      // };
      // client.onWebSocketClose = (event) => {
      //   client.activate();
      //   isSubscribe = false;
      // };
      // client.onWebSocketError = () => {
      //   // TODO handle
      //   isSubscribe = false;
      // };
      client.activate();
    } catch (err) {
      // if (isSubscribe) {
      //   client?.unsubscribe(MY_SUB_ID + '-' + (state.endpoint || ''));
      //   isSubscribe = false;
      //   client?.onConnect(frame);
      // }
    }
  },
  async unsubscribe({ commit, state }) {
    try {
      connected = false;
      isSubscribe = false;
      if (client) {
        client?.unsubscribe(MY_SUB_ID + '-' + (state.endpoint || ''));
        client?.deactivate();
        client = undefined;
      }
    } catch (error) {
      //TODO
    }
  },
  async fetchUnreadNotifications({ commit, state }) {
    try {
      let result: Object = await this.$axios.$get(
        `/api/v1/community/notifications/unread_number`
      );
      const unreadNotifications: number = result["data"]["amount"];
      commit(types.SET_UNREAD_NOTIFICATIONS, unreadNotifications);
    } catch (error) {
      // TODO : handle Error
    }
  },
  setUnreadNotifications({ commit, state }, unreadNotifications: number) {
    commit(types.SET_UNREAD_NOTIFICATIONS, unreadNotifications);
  },
  increaseUnreadNotifications({ commit, state }) {
    if (state.unreadNotifications == 0 || state.unreadNotifications) {
      commit(types.SET_UNREAD_NOTIFICATIONS, state.unreadNotifications + 1);
    }
  },
  resetUnreadNotifications({ commit, state }) {
    commit(types.SET_UNREAD_NOTIFICATIONS, 0);
  },
  decreaseUnreadNotifications({ commit, state }) {
    if (state.unreadNotifications && state.unreadNotifications > 0) {
      commit(types.SET_UNREAD_NOTIFICATIONS, state.unreadNotifications - 1);
    }
  },
  setNewNotification({commit, state}, newNotification: Notification) {
    commit(types.SET_NEW_NOTIFICATION, newNotification);
  },
  setNewRequestFocus({commit, state}, newRequestFocus: Notification) {
    commit(types.SET_NEW_REQUEST_FOCUS, newRequestFocus);
  }
};
function createStompClient() {
  return new Promise<Client>((resolve, reject) => {
    try {
      const stompClient = new Client({
        brokerURL: process.env.WS_URL,
        connectHeaders: {
          login: process.env.WS_URL_USER || '',
          passcode: process.env.WS_URL_PASSWORD || ''
        },
        debug: function (str) {
          // if (process.env.NODE_ENV === 'development') {
          // }
        },
        reconnectDelay: 1000,
        heartbeatIncoming: 0,
        heartbeatOutgoing: 20000,
        // connectionTimeout: 2000
      });
      if (typeof WebSocket != 'function') {
        stompClient.webSocketFactory = function () {
          return new SockJS(process.env.WS_URL_HTTP || '');
        };
      }
      resolve(stompClient)
    } catch (error) {
      reject(error)
    }
  });
}
export default actions;
