import Common from '~/modals/business_type'

export interface CurrencyState {
  currencies: Common[];
}

const state = (): CurrencyState => ({
  currencies: []
})

export default state
