import { MutationTree } from 'vuex'
import { BannerState } from './state'
import types from './types'
import { Banner } from '~/modals'

const mutations: MutationTree<BannerState> = {
  [types.FETCH_LIST](state, banners: Banner[]) {
    state.banners = banners
  }
}

export default mutations
