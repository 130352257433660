import { UserInfo } from '~/modals';
import { redirectUrl } from '~/utils/util';

export default ({ app, $auth, $formatSlugUrl, $fromUUID, route, redirect, store }) => {
  // var redirect = app.$auth.$storage.options.redirect
  // for (var key in redirect) {
  //   redirect[key] = '/' + app.i18n.locale + redirect[key]
  // }
  // app.$auth.$storage.options.redirect = redirect;
  store.dispatch('prefetchUser');
  const { localePath, i18n } = app;
  
  // setTimeout(() => {
  //   if ($auth.loggedIn && $auth.user.role === 'CANDIDATE') {
  //     let url = '';
  //     if((route.path === localePath('/') || route.path === (localePath('/') + '/')) && $auth.user.role === 'CANDIDATE') {
  //       url = redirectUrl($auth, localePath, $formatSlugUrl, $fromUUID, i18n.locale, '/forums');
  //       redirect(url);
  //       return;
  //     } else if ($auth.user.setting_language && $auth.user.setting_language.code) {
  //       // url = app.switchLocalePath($auth.user.setting_language.code);
  //       url = redirectUrl($auth, localePath, $formatSlugUrl, $fromUUID, $auth.user.setting_language.code, route.fullPath);
  //       redirect(url);
  //       return;
  //     }
  //   }
  // });
  $auth.onRedirect((to, from) => {
    const _url = redirectUrl($auth, localePath, $formatSlugUrl, $fromUUID, i18n.locale, to);
    return _url;
  });
}