import Vue from 'vue'
import VueViewer from 'v-viewer'
// import 'viewerjs/dist/viewer.css'
import '~/assets/css/viewer.scss'

Vue.use(VueViewer, {
  defaultOptions: {
    transition: false,
    title: false,
    rotatable: false,
    scalable: false,
    // movable: false,
    zoomOnWheel: false
  }
})