const regex = /(?:\b(MS)?IE\s+|\bTrident\/7\.0;.*\s+rv:)(\d+)/g;
export default (context, inject) => {
  const isIE = regex.test(navigator.userAgent);
  if (isIE == true) {
    // import('css-vars-ponyfill').then((cssVars) => {
    //   cssVars.default.call({
    //     onlyLegacy: false
    //   });
    // });
    try {

      // }
    } catch (error) {
    }
    window.addEventListener('unhandledRejection', function(event) {
      // Calling preventDefault() suppresses when.js's default rejection logging
      // in favor of your own.
      event.preventDefault();
      reportRejection((event as any).detail.reason, (event as any).detail.key);
    }, false);
    
    window.addEventListener('rejectionHandled', function(event) {
      // Calling preventDefault() suppresses when.js's default rejection logging
      // in favor of your own.
      event.preventDefault();
      reportHandled((event as any).detail.key);
    }, false);
    
    function reportRejection(error, key) {
      // Implement whatever logic your application requires
      // Log or record error state, etc.
    }
    
    function reportHandled(key) {
      // Implement whatever logic your application requires
      // Log that error has been handled, etc.
    }
    
    process.on('unhandledRejection', function(reason, key) {
      reportRejection(reason, key);
    });
    
    process.on('rejectionHandled', function(key) {
      reportHandled(key);
    });
    Promise.reject(new Error('woops'))
  }
  // inject('isIE', isIE);
  // context.$isIE = isIE;
}