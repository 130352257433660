import { MutationTree } from 'vuex'
import {Common} from '~/modals'
import { DepartmentState } from './state'
import types from './types'

const mutations: MutationTree<DepartmentState> = {
  [types.FETCH_LIST](state, departments: Common[]) {
    state.departments = departments
  }
}

export default mutations
