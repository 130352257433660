import { MutationTree } from 'vuex'
import {Common} from '~/modals'
import { LanguageState } from './state'
import types from './types'

const mutations: MutationTree<LanguageState> = {
  [types.FETCH_LIST](state, languages: Common[]) {
    state.languages = languages
  },
  [types.SET_LIST](state, languages: Common[]) {
    state.languages = languages
  }
}

export default mutations
