import { ActionTree } from 'vuex'
import { plainToClass } from "class-transformer";
import { RootState } from 'store'

import { TagState } from './state'
import {Common} from '~/modals'
import types from './types'

export interface Actions<S, R> extends ActionTree<S, R> {}

const actions: Actions<TagState, RootState> = {
  async fetchList({commit, state}) { 
    try{
      if(state.tags.length != 0) return;
      let results: Object[] = await this.$axios.$get("/api/v1/tags");
      const list = plainToClass(Common, results["data"]);
      commit(types.FETCH_LIST, list)
    } catch(error){
      // TODO : handle Error
    }
  },
  async getGeneralTags({commit, state}) { 
    try{
      if(state.generalTags.length != 0) return;
      let results: Object[] = await this.$axios.$get("/api/v1/tags/general");
      const list = plainToClass(Common, results["data"]);
      commit(types.GET_GENERAL_TAG, list)
    } catch(error){
      // TODO : handle Error
    }
  },
  async getHashTags({commit, state}) { 
    try{
      if(state.hashTags.length != 0) return;
      let results: Object[] = await this.$axios.$get("/api/v1/tags/specific");
      const list = plainToClass(Common, results["data"]);
      commit(types.GET_HASH_TAG, list)
    } catch(error){
      // TODO : handle Error
    }
  }
}

export default actions
