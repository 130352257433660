import {Common} from '~/modals'

export interface TagHashtagState {
  tagHashtags: Common[];
}

const state = (): TagHashtagState => ({
  tagHashtags: []
})

export default state
