import axios from 'axios';
import config from '../config';
import { namespace } from "vuex-class";
import {
  Candidate,
} from "~/modals";
const AccountDeletedModalStore = namespace("account-deleted-modal");



const _axios = axios.create({
  baseURL : config.baseURL,
  headers: {
    'Content-Type' : 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*'
  }
});
export default function ({ app, $axios, redirect, error, store, route }) {

  let loadedOnce = false;
  $axios.onError(err => {
    const {url} = err.config;
    if (!err.response && err.message == 'Network Error' && url !== '/api/v1/community/notifications/endpoint') {
      if (store.$auth.loggedIn) {
        if (loadedOnce) {
          return;
        }
        loadedOnce = true;
        error({statusCode: 400, message: 'ERROR_ERR_NETWORK_DISCONNECTED'})
      }
    }
  })
  $axios.onResponseError(err => {
    // const code = parseInt(err.response && err.response.status);
    const error = err.response?.data?.error;
    // let originalRequest = err.config;
    // if (code === 403 && 'ERR.TOK0102' === (error?.code ?? '')) {
    //   originalRequest.__isRetryRequest = true;
    //   const token = app.$auth.getRefreshToken('local');
    //   return new Promise((resolve, reject) => {
    //     _axios.post(`/api/v1/refresh_tokens`, { grant_type: 'refresh_token', refresh_token: token })
    //     .then(response => {
    //       if (response.status == 200) {
    //         const { data } = response.data;
    //         app.$auth.setToken('local', data.token_type + ' ' + data.access_token);
    //         app.$auth.setRefreshToken('local', data.refresh_token);
    //         originalRequest.headers['Authorization'] = `${data.token_type} ${data.access_token}`;
    //         $axios.defaults.headers.common['Authorization'] = `${data.token_type} ${data.access_token}`;
    //       }
    //       resolve(response);
    //     }).catch(e => {
    //       reject("some message");
    //     });
    //   }).then(res => {
    //     return $axios(originalRequest);
    //   }).catch(e => {
    //     app.$auth.reset();
    //     app.router.push('/');
    //   });
    // } else if (['ERR.TOK0101', 'ERR.TOK0103', 
    //             'ERR.TOK0201', 'ERR.TOK0202', 'ERR.TOK0203'].includes((error?.code ?? ''))) {
    //   app.$auth.reset();
    // }
    if (error?.code && ['ERR.TOK0101', 'ERR.TOK0103', 
        'ERR.TOK0201', 'ERR.TOK0202', 'ERR.TOK0203'].includes(error?.code)) {
        app.$auth.reset();
        // setTimeout(() => {
          store.dispatch('account-deleted-modal/changeShowModal', true);
        // }, 100)
        return Promise.resolve(false);
        // setTimeout(() =>{
        //   store.dispatch('account-deleted-modal/changeShowModal', false);
        //   store.dispatch('category/clearCategory');
        //   store.dispatch('categoryHashtag/clearCategoryHashtag');
        //   store.dispatch('profile/clearToProfile');        
        //   store.dispatch('userInfo/clearUserInfo');
        //   store.dispatch('candidate/setCurrentCandidate', new Candidate());
        //   store.dispatch('notification/resetUnreadNotifications');
        //   store.dispatch('message/resetUnreadMessages');
        //   app.router.push(app.localePath('/'));

        //   const interval_id = window.setInterval(function(){}, Number.MAX_SAFE_INTEGER);
        //   // Clear any timeout/interval up to that id
        //   for (let i = 1; i < interval_id; i++) {
        //     window.clearInterval(i);
        //   }
        // }, 5000)
      }
    }
  );
  $axios.onRequest(config => {
    // console.log(config.url)
    // if(config.url == "/api/v1/refresh_tokens"){
    //   config.headers.Authorization = undefined;
    // }
  });
  $axios.onResponse(res=>{
    //console.log("have axios res :", res["data"]["status"]);
  });
}

