import { Banner } from '~/modals'

export interface BannerState {
  banners: Banner[];
}

const state = (): BannerState => ({
  banners: []
})

export default state
