import { GetterTree } from 'vuex'
import { RootState } from 'store'
import { LoadingState } from './state'
const getters: GetterTree<LoadingState, RootState> = {
  getLoading: (state) => {
    return state.loading
  },
  getShowConfirm: (state) => {
    return state.showConfirm
  }
}
export default getters
