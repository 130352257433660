import { GetterTree } from 'vuex'
import { RootState } from 'store'
import { ReportState } from './state';

function cloneSource(source) {
  return JSON.parse(JSON.stringify(source));
}
const getters: GetterTree<ReportState, RootState> = {
  getReports: (state) => {
    return cloneSource(state.reports);
  },
  getSpecifics: (state) => {
    return cloneSource(state.specificReports);
  },
  getSpecificsType: (state) => {
    return cloneSource(state.specificTypes);
  },
  getSpecificAllType: (state) => {
    return state.specificAllTypes;
  },
  getReportAllType: (state) => {
    return state.reportAllTypes;
  }
}
export default getters
