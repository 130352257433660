import { ActionTree, ActionContext } from 'vuex'
import { RootState } from 'store'
import { UserState } from './state'
import { UserInfo } from '~/modals'
import types from './types'

export interface Actions<S, R> extends ActionTree<S, R> {
  setUserInfo(context: ActionContext<S, R>, userInfo: UserInfo): void;
}

const actions: Actions<UserState, RootState> = {
  setUserInfo({ commit, state }, userInfo: UserInfo) {
    commit(types.SET_USER_INFO, {...(state.userInfo || {}), ...userInfo});
  },
  clearUserInfo({ commit, state }) {
    commit(types.SET_USER_INFO, {});
  },
  setAvatarUserInfo({ commit, state }, avatar) {
    commit(types.SET_USER_INFO, {...state.userInfo , avatar});
  },
  setActiveUserInfo({ commit, state }, active) {
    commit(types.SET_USER_INFO, { ...state.userInfo, active });
  }
}

export default actions
