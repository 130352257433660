import { MutationTree } from "vuex";
import { NotificationState } from "./state";
import types from "./types";
import { Notification, NotificationVideoCall, InterviewPoll } from "@/modals";
import { Client } from "@stomp/stompjs";

const mutations: MutationTree<NotificationState> = {
  [types.SET_NEW_NOTIFICATION](state, newNotification: Notification) {
    if(newNotification.message && newNotification.message['files']) {
      newNotification.files = newNotification.message['files'];
    }
    state.newNotification = newNotification;
  },
  [types.SET_UNREAD_NOTIFICATIONS](state, unreadNotifications: number) {
    state.unreadNotifications = unreadNotifications;
  },
  [types.SET_END_POINT](state, endpoint: string) {
    state.endpoint = endpoint;
  },
  [types.SET_NEW_REQUEST_FOCUS](state, newRequestFocus: Notification) {
    state.newRequestFocus = newRequestFocus;
  },
  [types.SET_SUBSCRIBE_VIDEO_CALL](state, newNotificationVideoCall: NotificationVideoCall) {
    state.newNotificationVideoCall = newNotificationVideoCall;
  },
  [types.SET_USER_ID](state, userID: string) {
    state.userID = userID;
  },
  [types.SET_JOB_APPLICATION_ID](state, jobApplicationId: string) {
    state.jobApplicationId = jobApplicationId;
  },
  [types.SET_SUBSCRIBE_INTERVIEW_POLL](state, newNotificationInterviewPoll: InterviewPoll[]) {
    state.newNotificationInterviewPoll = newNotificationInterviewPoll;
  },
  [types.SET_CONNECTED](state, connected: boolean) {
    state.connected = connected;
  },
  [types.SET_NEW_NOTIFICATION_OF_FORUM](state, newNotificationOfForum: Notification) {
    state.newNotificationOfForum = newNotificationOfForum;
  }
};

export default mutations;
