export class REQUEST_TYPES {
  REQUEST_JOB_APPLICATION_SUPPORT: string = "REQUEST_JOB_APPLICATION_SUPPORT";
  REQUEST_COMPANY_TRANSLATION: string = "REQUEST_COMPANY_TRANSLATION";
  REQUEST_JOB_TRANSLATION: string = "REQUEST_JOB_TRANSLATION";
  REQUEST_CANDIDATE_TRANSLATION: string = "REQUEST_CANDIDATE_TRANSLATION";
}

export class NOTIFICATION_TYPES {
  MESSAGE_REQUEST: string = "MESSAGE_REQUEST";
  MESSAGE_JOB_APPLICATION: string = "MESSAGE_JOB_APPLICATION";
  STATUS_REQUEST: string = "STATUS_REQUEST";
  STATUS_JOB_APPLICATION: string = "STATUS_JOB_APPLICATION";
}

export class ACTIVITIES {
  HELPER_JOINED: string = "HELPER_JOINED";
  HELPER_CANCEL: string = "HELPER_CANCEL";
  HELPER_FINISHED: string = "HELPER_FINISHED";

  OWNER_ACCEPTED_APPLY: string = "OWNER_ACCEPTED_APPLY";
  OWNER_REJECT_APPLY: string = "OWNER_REJECT_APPLY";
  OWNER_ACCEPTED_FINISHED: string = "OWNER_ACCEPTED_FINISHED";
  OWNER_REFUSED_FINISHED: string = "OWNER_REFUSED_FINISHED";
  OWNER_CANCEL: string = "OWNER_CANCEL";

  CANDIDATE_JOINED: string = "CANDIDATE_JOINED";
  CANDIDATE_CANCEL: string = "CANDIDATE_CANCEL";

  COMPANY_ACCEPTED_APPLY: string = "COMPANY_ACCEPTED_APPLY";
  COMPANY_REJECT_APPLY: string = "COMPANY_REJECT_APPLY";
  COMPANY_ACCEPTED_CANDIDATE: string = "COMPANY_ACCEPTED_CANDIDATE";
  COMPANY_REFUSED_CANDIDATE: string = "COMPANY_REFUSED_CANDIDATE";
  FINISHED: string = "FINISHED";

}

export class TRANSLATION_STATUS {
  TRANSLATION_TRANSLATING: string = "TRANSLATING";
  TRANSLATION_REVIEWED: string = "REVIEWED";
  TRANSLATION_COMPLETED: string = "COMPLETED";
  TRANSLATION_NOT_COMPLETED: string = "NOT_COMPLETED";
}
// export class CAREER_PATH_OPTION {
//   WORK_IN_JAPAN_FOREVER: string = "WORK_IN_JAPAN_FOREVER";
//   WORKING_FOR_SEVERAL_YEARS_IN_JAPAN: string = "WORKING_FOR_SEVERAL_YEARS_IN_JAPAN";
//   WORKING_FOR_A_LONG_TIME_IN_JAPAN: string = "WORKING_FOR_A_LONG_TIME_IN_JAPAN";
//   WORKING_FOR_A_SHORT_TIME_IN_JAPAN: string = "WORKING_FOR_A_SHORT_TIME_IN_JAPAN";
//   WORK_AT_THIRD_COUNTRIES: string = "WORK_AT_THIRD_COUNTRIES";
//   WORK_GLOBALLY: string = "WORK_GLOBALLY";
//   OTHER: string = "OTHER";
// }