import { GetterTree } from 'vuex'
import { RootState } from 'store'
import { BannerState } from './state'
import { Banner } from '~/modals';

const getters: GetterTree<BannerState, RootState> = {
  getBanners: (state) => {
    let sortBanner: Banner[] = new Array<Banner>(state.banners.length);
    state.banners.forEach(o => {
      if(o.bannerPosition == 'FIRST'){
        sortBanner[0] = o;
      }else if(o.bannerPosition == 'SECOND'){
        sortBanner[1] = o;
      }else if(o.bannerPosition == 'THIRD'){
        sortBanner[2] = o;
      }
    });
    return sortBanner;
  }
}
export default getters
