
import slugify from 'slugify'

export default (context, inject) => {
    const toSlugify = url => slugify(url, {
      replacement: '-',
      remove: /[*+~.,()'"!:@/#]/g,
      lower: true,
      strict: false,
      locale: 'vi'
    });
    const { $toUUID } = context;
    const formatSlugUrl = (name, id) => {
      return toSlugify(name) + '-' + id;
    }
    const idFromSlug = (slug: string, callback: Function) => {
      const arr = slug.split('-');
      const id = arr[arr.length - 1];
      return callback(id);
    }
    const getIdBySlug = (slug) => {
      return idFromSlug(slug, $toUUID);
    };

    // Inject $slugify(url) in Vue, context and store.
    inject('slugify', toSlugify);
    // Inject $formatSlugUrl(url, id) in Vue, context and store.
    inject('formatSlugUrl', formatSlugUrl);
    // Inject $formatSlugUrl(url, id) in Vue, context and store.
    inject('idFromSlug', idFromSlug);
    // Inject $getIdBugSlug(url, id) in Vue, context and store.
    inject("getIdBySlug", getIdBySlug);

    context.$slugify = toSlugify;
    context.$formatSlugUrl = formatSlugUrl;
    context.$idFromSlug = idFromSlug;
    context.$getIdBySlug = getIdBySlug;
  }