import { MutationTree } from 'vuex'
import { LoadingState } from './state'
import types from './types'

const mutations: MutationTree<LoadingState> = {
  [types.SET_LOADING](state, loading: boolean) {
    state.loading = loading
  },
  [types.SET_SHOW_CONFIRM](state, showConfirm: boolean) {
    state.showConfirm = showConfirm
  },
}

export default mutations
