const types = {
  ADD_PROFILE_ID_TO_QUEUE: 'ADD_PROFILE_ID_TO_QUEUE',
  ADD_QUEUE_IDS_TO_FETCH_LIST: 'ADD_QUEUE_IDS_TO_FETCH_LIST',
  CLEAR_QUEUE: 'CLEAR_QUEUE',
  ADD_TO_PROFILE: 'ADD_TO_PROFILE',
  CLEAR_TO_PROFILE: 'CLEAR_TO_PROFILE',
  CLEAR_FETCH_ID: 'CLEAR_FETCH_ID',
  REPLACE_PROFILE: 'REPLACE_PROFILE',
  CLEAR_PROFILE: 'CLEAR_PROFILE'
}

export default types
