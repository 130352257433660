import { MutationTree } from 'vuex'
import { MessageState } from './state'
import types from './types'

const mutations: MutationTree<MessageState> = {
  [types.SET_MESSAGE](state, message: string) {
    state.message = message
  },
}

export default mutations
