import { MutationTree } from 'vuex'
import {Common} from '~/modals'
import { CategoryState } from './state'
import types from './types'

const mutations: MutationTree<CategoryState> = {
  [types.FETCH_LIST](state, categories: Common[]) {
    state.categories = categories
  },
  [types.FETCH_LIST_NEWS_CATEGORY](state, newsCategories: Common[]) {
    state.newsCategories = newsCategories
  }
}

export default mutations
