import {Common} from '~/modals'

export interface EducationState {
  educations: Common[];
}

const state = (): EducationState => ({
  educations: []
})

export default state
