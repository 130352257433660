
import { Report } from '~/modals';
import { GENERAL_TYPE, GENERAL_TYPES } from './types';
export interface ReportState {
  reports: Report[],
  specificReports: Report[],
  specificTypes: Report[],
  showReport: boolean,
  typeReport: GENERAL_TYPE,
  reportFunctionId: string,
  reportAllTypes: {
    [key in GENERAL_TYPES]: Report[]
  },
  specificAllTypes: {
    [key in GENERAL_TYPES]: Report[]
  },
  removeQuestion: string,
}

const state = (): ReportState => ({
  reports: [],
  specificReports: [],
  specificTypes: [],
  showReport: false,
  typeReport: '',
  reportFunctionId: '',
  reportAllTypes: {
    JOB: [],
    COMMUNITY: [],
    BLOG: [],
    CANDIDATE: [],
    COMPANY: [],
    TRANSLATOR: [],
    BLOG_COMMENT: [],
    JOB_COMMENT: [],
    COMMUNITY_ANSWER: [],
    NEWS_COMMENT: []
  },
  specificAllTypes: {
    JOB: [],
    COMMUNITY: [],
    BLOG: [],
    CANDIDATE: [],
    COMPANY: [],
    TRANSLATOR: [],
    BLOG_COMMENT: [],
    JOB_COMMENT: [],
    COMMUNITY_ANSWER: [],
    NEWS_COMMENT: []
  },
  removeQuestion: '',
});

export default state
