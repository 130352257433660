import { Candidate } from '~/modals'
export interface CandidateState {
  candidateID: string;
  candidate: Candidate;
  candidates: Candidate[]
}

const state = (): CandidateState => ({
  candidateID: '',
  candidate: new Candidate(),
  candidates: [],
})

export default state
