import { ActionTree } from "vuex";
import { plainToClass } from "class-transformer";
import { RootState } from "store";
import { LanguageState } from "./state";
import { Language, Common } from "~/modals";
import types from "./types";

export interface Actions<S, R> extends ActionTree<S, R> {}

const actions: Actions<LanguageState, RootState> = {
  async fetchList({ commit, state }) {
    try {
      if (state.languages.length != 0) return;
      let results: Object[] = await this.$axios.$get("/api/v1/languages");
      const list = plainToClass(Language, results["data"]);
      commit(types.FETCH_LIST, list);
    } catch (error) {
      // TODO : handle Error
    }
  },
  setLanguages({ commit }, languages: Common[]) {
    commit(types.SET_LIST, languages)
  }
};

export default actions;
