import { plainToClass } from "class-transformer";
import "reflect-metadata";
import { GetterTree, ActionContext, ActionTree, MutationTree } from "vuex";
import { Candidate, UserInfo } from "~/modals";
import NScheme from "~/schemes/NScheme";

export const types = {};

export interface RootState {}

export interface State {}

export const state = (): State => ({});

export const rootState = (): RootState => ({});

export const getters: GetterTree<State, RootState> = {};

export interface Actions<S, R> extends ActionTree<S, R> {
  nuxtServerInit(context: ActionContext<S, R>, any): Promise<any>;
}

async function fetchAvatarAndName(self, dispatch) {
  const { $auth } = self;
  if ($auth.user?.property_id) {
    const role: string = $auth.user.role;
    const propertyID: string = $auth.user.property_id;
    if (role === "CANDIDATE") {
      let result: Object = await self.$axios.$get(
        `/api/v1/candidates/${propertyID}`
      );
      const candidate = plainToClass(Candidate, result["data"]);
      await dispatch('candidate/setCurrentCandidate', candidate);
      await updateUserInfo(candidate.avatar || '', candidate.firstName || '', $auth, dispatch);
    }
  }
}

async function updateUserInfo(avatar: string, name: string, $auth, dispatch) {
  const _userInfo = {...$auth.user, avatar: avatar, name: name};
  await dispatch('userInfo/setUserInfo', plainToClass(UserInfo, _userInfo));
}

export const actions: Actions<State, RootState> = {
  nuxtServerInit({ commit, dispatch, state }, { req, app, query }) {
    // const token = app.$auth.strategy.token.get().substr(7);
    // if(token) {
    //   app.$cookiz.set('token', token, {domain: '.test.localhost'})
    // }
    return Promise.all([
      dispatch('language/fetchList'),
      dispatch('country/fetchCountries'),
      dispatch("city/fetchList"),
      dispatch('currency/fetchList'),
      dispatch('banner/fetchList'),
      dispatch('tag/fetchList')
    ]);

  },
  async prefetchUser({ dispatch, state }) {
    const author = await dispatch('profile/fetchNewProfileById', this.$auth?.user?.property_id ? this.$auth?.user?.id : '');
    const _userInfo = {...this.$auth?.user ?? {}, avatar: author.avatar, name: author.name};
    const parserUserInfo = plainToClass(UserInfo, _userInfo);
    const compareObj = JSON.stringify(parserUserInfo) === JSON.stringify((state as any).userInfo.userInfo);
    !compareObj && (await dispatch('userInfo/setUserInfo', parserUserInfo));
  }
};

export const mutations: MutationTree<State> = {};
