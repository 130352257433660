import axios from 'axios';
import config from '../config';
import { redirectUrl } from '~/utils/util';

const _axios = axios.create({
  baseURL : config.baseURL,
  headers: {
    'Content-Type' : 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*'
  }
});

export default async function({ app, store, redirect, $axios, $formatSlugUrl, $fromUUID, route, localePath }) {
  // $auth.onRedirect((to, from) => {
  //   return app.localePath({
  //     name: "index"
  //   });
  // });
  if (!app.$auth.loggedIn) {
    return
  }

  const auth = app.$auth;
  const authStrategy = auth.strategy.name;
  if(authStrategy === 'google' || authStrategy === 'facebook'){
    const locale = auth.$storage.getUniversal("locale_user");
    auth.$storage.removeUniversal("locale_user");
    // const _token_type = auth.getToken(authStrategy).substr(0, 6);
    const _token_type = auth.strategy.token.get().substr(0, 6);
    // const _token = auth.getToken(authStrategy).substr(7);
    const _token = auth.strategy.token.get().substr(7);
    let url = `api/v1/user/google/login`;
    if(authStrategy === 'facebook') url = 'api/v1/user/facebook/login';
    try {
      const result = await _axios.post(url, {
        access_token: _token,
        token_type: _token_type,
        provider: authStrategy.toUpperCase(),
        sign_in_service: 'COMMUNITY',
        language: locale
      });
      if (result.status === 200) {
        const {data} = result['data'];
        auth.setStrategy('local');
        // auth.setToken('local', data.token_type + ' ' + data.access_token);
        // auth.setRefreshToken('local', data.refresh_token);
        await auth.setUserToken(data.access_token, data.refresh_token);
        setTimeout( async () => {
          setTimeout( async () => {
            await auth.fetchUser();
            if (auth.user) {
              if (auth.user.role === 'USER') {
                // const result2 = await _axios.post(`api/v1/user/role`, {
                //   'role': 'CANDIDATE'
                // }).then(async (result) => {
                //   auth.setUser(result['data']['data']);
                //   if (auth.user && !auth.user.support_language) {
                //     return redirect(app.localePath({
                //       name: "countries"
                //     }, locale));
                //   }
                // }).catch((err) => {
                // });
                return redirect(app.localePath({
                  name: "roles"
                }, locale));
              } else if (auth.user.role === 'CANDIDATE'){
                if(!auth.user.setting_language) {
                  return redirect(app.localePath({
                    name: "countries"
                  }, locale));
                }
                return redirect(app.localePath({
                  name: "forums"
                }, (auth.user.setting_language && auth.user.setting_language.code) || store.$i18n.locale));
              }
            }
          });
        });
      }
    } catch (e: any) {
      const {error} = e.response.data;
      if (error && error.code && (error.code === 'ERR.AUTH0201' || error.code === 'ERR.AUTH0202' || error.code === 'ERR.AUTH0203' || error.code === 'ERR.AUTH0204')) {
        app.$auth.logout();
        setTimeout( async () => {
          return redirect(app.localePath({
            name: "login",
            query: {error: error.code }
          }, locale || store.$i18n.locale));
        });
      }
      else if (error && error.code ) {
        app.$auth.logout();
        setTimeout( async () => {
          return redirect(app.localePath({
            name: "login",
            query: {error: error.code}
          }, locale || store.$i18n.locale));
        });
      } else {
        app.$auth.logout();
        setTimeout( async () => {
          return redirect(app.localePath({
            name: "login",
          }, locale || store.$i18n.locale));
        });
      }
      //   return redirect(app.localePath({
      //     name: "api-oauth2-oauth-error"
      //   }, locale));
      // }
      // return redirect(app.localePath({
      //   name: "login"
      // }, locale));
    }
  } else {
    setTimeout(() => {
      if (app.$auth.loggedIn && 
        (route.path === localePath('/') || route.path === (localePath('/') + '/'))) {
        const url = redirectUrl(app.$auth, localePath, $formatSlugUrl, $fromUUID, store.$i18n.locale, '/');
        redirect(url);
      }
    });
  }
}
