import { MutationTree } from 'vuex'
import {Common} from '~/modals'
import { TagHashtagState } from './state'
import types from './types'

const mutations: MutationTree<TagHashtagState> = {
  [types.FETCH_LIST](state, tagHashtags: Common[]) {
    state.tagHashtags = tagHashtags
  }
}

export default mutations
