const types = {
  GET_GENERAL_REPORT: 'GET_GENERAL_REPORT',
  GET_SPECIFIC_BY_ID: 'GET_SPECIFIC_BY_ID',
  SEND_REPORT: 'SEND_REPORT',
  SHOW_REPORT: 'SHOW_REPORT',
  HIDE_REPORT: 'HIDE_REPORT',
  SET_TYPE: 'SET_TYPE',
  GET_SPECIFICS: 'GET_SPECIFICS',
  SET_REPORT_ALL_TYPE: 'SET_REPORT_ALL_TYPE',
  SET_SPECIFIC_ALL_TYPE: 'SET_SPECIFIC_ALL_TYPE',
  REMOVE_QUESTION: 'REMOVE_QUESTION'
}
export default types;

export type GENERAL_TYPE = ''|'JOB'|'COMPANY'|'CANDIDATE'|'TRANSLATOR'|'BLOG'|'COMMUNITY'|'BLOG_COMMENT'|'COMMUNITY_ANSWER'|'JOB_COMMENT'|'NEWS_COMMENT';
export type GENERAL_TYPES = 'JOB'|'COMPANY'|'CANDIDATE'|'TRANSLATOR'|'BLOG'|'COMMUNITY'|'BLOG_COMMENT'|'COMMUNITY_ANSWER'|'JOB_COMMENT'|'NEWS_COMMENT';
