import { MutationTree } from "vuex";
import { Country } from "~/modals";
import { CountryState } from "./state";
import types from "./types";

const mutations: MutationTree<CountryState> = {
  [types.SET_COUNTRIES](state, countries: Country[]) {
    state.countries = countries;
  }
};

export default mutations;
