import {Common} from '~/modals'

export interface TagState {
  tags: Common[];
  generalTags: Common[];
  hashTags: Common[];
}

const state = (): TagState => ({
  tags: [],
  generalTags: [],
  hashTags: []
})

export default state
