import { ActionTree } from 'vuex'
import { RootState } from 'store'
import { plainToClass } from "class-transformer";
import { CandidateState } from './state'
import types from './types'
import { Candidate } from '~/modals'

export interface Actions<S, R> extends ActionTree<S, R> {}

const actions: Actions<CandidateState, RootState> = {
  async fetchCandidate({ commit }, info: {id: string, language: string}) {
    try{
      let result: Object = await this.$axios.$get(`/api/v1/candidates/${info.id}?language=${info.language}`);
      const candidate = plainToClass(Candidate, result["data"]);

      commit(types.SET_CANDIDATE, candidate);
    } catch(e){
      console.error('fetch candidate error ' + e);
    }
  },
  async setCurrentCandidate({ commit }, candidate: Candidate) {
    commit(types.SET_CANDIDATE, candidate);
  }
}

export default actions
