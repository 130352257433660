import {Common} from '~/modals'

export interface LanguageState {
  languages: Common[];
}

const state = (): LanguageState => ({
  languages: []
})

export default state
