import Vue from "vue";
import { REQUEST_TYPES, NOTIFICATION_TYPES, ACTIVITIES } from "@/predefineds/enum";
const pluralize = require('pluralize');
import moment from 'moment';
import { findArray } from '@/utils/processArray';

const FORMAT_DATE = {
  'en': 'MM/DD/YYYY',
  'ja': 'YYYY/MM/DD',
  'vi': 'DD/MM/YYYY',
  'pt': 'MM/DD/YYYY',
}

Vue.mixin({
  data: () => ({
    REQUEST_TYPES: new REQUEST_TYPES(),
    NOTIFICATION_TYPES: new NOTIFICATION_TYPES(),
    ACTIVITIES: new ACTIVITIES(),
  }),
  methods: {
    formatDate(timestamp: number): string {
      const date = new Date(timestamp);
      return (
        date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear()
      );
    },
    uniqueArray(unprocessedArray: string[]): string[] {
      var seen = {};
      var processedArray: string[] = [];
      var length = unprocessedArray.length;
      var j = 0;
      for (var i = 0; i < length; i++) {
        var item = unprocessedArray[i];
        if (seen[item] !== 1) {
          seen[item] = 1;
          processedArray[j++] = item;
        }
      }
      return processedArray;
    },
    setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + (exdays*24*60*60*1000));
      const expires = "expires="+d.toUTCString();
      document.cookie = cname + "=" + cvalue + "; " + expires;
    },
    setCookieByMinute(cname, cvalue, expireTimeByMinute) {
      const d = new Date();
      d.setTime(d.getTime() + (expireTimeByMinute*60*1000));
      const expires = "expires="+d.toUTCString();
      document.cookie = cname + "=" + cvalue + "; " + expires ;
    },

    getCookie(cname) {
        const name = cname + "=";
        const ca = document.cookie.split(';');
        for(let i=0; i<ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1);
            if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
        }
        return "";
    },
    formatLocaleDate(_dateTime, formatDate='L', locale='') {
      if (locale) {
        return this.$moment(_dateTime).locale(locale).format(formatDate);
      }
      return this.$moment(_dateTime).locale(this.$i18n.locale).format(formatDate);
    },
    formatDateTimeByLanguageCode(_dateTime, lang) {
      const _format = FORMAT_DATE[lang];
      return this.$moment(_dateTime).format(_format); 
    },
    addressByLocale(address: string, cityId: string, countryId: string = 'en', queyParam: string = '') {
      const _code = this.$route.query[queyParam] || 
        (this.$auth.user ? this.$auth.user.support_language.code : this.$i18n.locale.toString())
      const city = findArray(this.$store.state.city.cities, _code as string, cityId);
      if (this.$auth.loggedIn && _code === 'ja') {
        return (city.name || '') + ', ' + address;
      }
      const country = findArray(this.$store.state.country.countries, _code as string, countryId);
      return address + ', ' + (city.name || '') + ', ' + (country.name || '');
    },
    forumsCommentDisplayTime (value) {
      if (!value) {
        return '-';
      }
      let seconds = Math.abs(this.$moment(Number(value)).diff(this.$moment(),'seconds'));
    
      let interval = seconds / 31536000;
    
      if (interval > 1) {
        return Math.floor(interval) + ' ' + this.$tc('time.year_forums', Math.floor(interval)).toString();
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + ' ' + this.$tc('time.month_forums', Math.floor(interval)).toString();
      }
      interval = seconds / 604800;
      if (interval > 1) {
        return Math.floor(interval) + ' ' + this.$tc('time.week_forums', Math.floor(interval)).toString();
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + ' ' + this.$tc('time.day_forums', Math.floor(interval)).toString();
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + ' ' + this.$tc('time.hour_forums', Math.floor(interval)).toString();
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + ' ' + this.$tc('time.minute_forums', Math.floor(interval)).toString();
      }
      return this.$t('time.just_now_forums').toString();
    },
    dynamicSort(property) {
      //sorts alphabetically by the name key
      //Ex ascending : this.filteredArrayLanguageCode(this.cleanSource(this.departments), this.$i18n.locale).sort((this as any).dynamicSort("name"));
      //Ex descending   : this.filteredArrayLanguageCode(this.cleanSource(this.departments), this.$i18n.locale).sort((this as any).dynamicSort("-name"))

      let sortOrder = 1;
  
      if(property[0] === "-") {
          sortOrder = -1;
          property = property.substr(1);
      }
  
      return function (a,b) {
          if(sortOrder == -1){
              return b[property].localeCompare(a[property]);
          }else{
              return a[property].localeCompare(b[property]);
          }        
      }
    },
    redirectLoginToUrl() {
      const noRedirectLogins = [this.localePath('/'), this.localePath('/') + '/', this.localePath('/login'), 
        this.localePath('/sign-up'), this.localePath('/sign-up'), this.localePath('/forgot-password')];
      if (noRedirectLogins.includes(this.$route.path)) {
        this.$router.push(
          (this as any).localePath({
            name: "login"
          })
        );
      } else {
        this.$auth.redirect('login');
      }
    },
    compareObject(o1, o2) {
      return typeof o1 === 'object' && o1 !== null && Object.keys(o1).length > 0 
        ? Object.keys(o1).length === Object.keys(o2).length 
        && Object.keys(o1).every(p => this.compareObject(o1[p], o2[p]))
        : o1 == o2;
    }
  }
});

Vue.filter('linkify', _linkify);

function _linkify(text) {
  const regexString = /(([Hh]ttp:\/\/|[Hh]ttps:\/\/|[Ff]tp:\/\/|^|\s)[\w/\-?=%.]+\.[-a-zA-Z0-9@:%_\+.~#?&//=]+)/g;
  return text.replace(regexString, function (url) {
    let link = url.trim();
    if((link).startsWith("http://") || (link).startsWith("https://")) {
      return `<a href="${link}" class="ref_link_chat_message" target="_blank">${url}</a>`;
    } else {
      return `<a href="http://${link}" class="ref_link_chat_message" target="_blank">${url}</a>`;
    }
  });
}
const _pluralize = function(value, language, count, inclusive = false) {
  if (language === 'en') {
    return pluralize(value, count, inclusive);
  }
  return value;
}
Vue.filter('pluralize', _pluralize);

const formatDateByLocale = (value, language = 'en') => {
  if (!value) {
    return '-';
  }
  const formatDate = FORMAT_DATE[language];
  return moment(Number(value)).locale(language).format(formatDate);
}
Vue.filter('formatDateByLocale', formatDateByLocale);