import short from 'short-uuid';

export default (context, inject) => {
    const fromUUID = UUID => short().fromUUID(UUID);
    const toUUID = shortID => short().toUUID(shortID);
    const generateUUID = () => short().uuid();

    // Inject $fromUUID(UUID) in Vue, context and store.
    inject('fromUUID', fromUUID);
    // Inject $toUUID(shortID) in Vue, context and store.
    inject('toUUID', toUUID);
    // Inject $generateUUID() in Vue, context and store.
    inject('generateUUID', generateUUID);

    context.$fromUUID = fromUUID;
    context.$toUUID = toUUID;
    context.$generateUUID = generateUUID;
  }