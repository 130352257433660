import Vue from 'vue'
import VeeValidate, { Validator, Rules  } from 'vee-validate'
import { date_between } from 'vee-validate/dist/rules.esm';

Vue.use(VeeValidate, {
  inject: true
  // fieldsBagName: 'veeFields'
});

//Custom validator rules
Validator.extend('verify_password', {
  validate: value => {
    const strongRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}/gm;
    return strongRegex.test(value);
  }
});

Validator.extend('password_match', {
  validate: value => {
    return false;
  }
});

Validator.extend('password_not_match', {
  validate: value => {
    return false;
  }
});

//Custom max value messages error separate dot in long number
Validator.extend('max_value_custom', {
  validate: (value, args) => {
    if (parseInt(value) <= (args as any)[0]) return true;
    return false;
  }
});

//Custom validator rules duplicate value
Validator.extend('duplicate_values', {
  validate: (value, args) => {
    const options = (args as any)[0] || [];
    const keyValidate = (args as any)[1] || '';
    const filterdValues = (options as [])?.filter(_o => (keyValidate ? _o[keyValidate] : _o) == value) ?? [];
    return filterdValues.length < 2;
  },
  getMessage(field, params, data) {
    
    return field + ' ' + data.message;
  }
});
Validator.extend('regex_score', {
  validate: value => {
    const strongRegex = /^([0-9])*(\.[0|5])?$/gm;
    return strongRegex.test(value);
  }
});
//Custom validator rules equal date by time
Validator.extend('date_time_between', {
  validate: (value, ...args) => {
    const opt: string[] = args[0] as [];
    if (!opt[0]) {
      return true;
    }
    if(opt[2] === opt[3]) {
      return date_between.validate(value, {min: opt[0], max: opt[1], format: 'HH:mm', inclusivity: ''})
    }
    return true;
  }
});

Validator.extend('required_time_poll', {
  validate: (value) => {
    return {
      valid: !['', null, undefined].includes(value),
      data: {
        required: true
      }
    };
  },
}, {computesRequired: true});

Validator.extend('max_custom', {
  validate: (value, args) => {
    
    return value.trim().length <= (args as any)[0]
  }
});
//Custom validator messages i18n
Validator.localize({
  vi: {
    messages: {
      verify_password: 'Mật khẩu có ít nhất 8 ký tự, chứa ít nhất 1 chữ cái viết hoa, 1 chữ cái viết thường, 1 ký tự số',
      password_match: 'Mật khẩu mới phải khác mật khẩu hiện tại',
      password_not_match: "Xác nhận mật khẩu mới không khớp",
      duplicate_values: (field, params, data)=> field + ' ' + 'không được trùng lặp!',
      regex_score: 'Điểm số là bội số của 0.5',
      date_time_between: (field, params, data)=> field +' phải có giá trị nằm trong khoảng giữa ' + params[0] + ' và ' + params[1],
      required_time_poll: (field, params, data) => '',
      max_value_custom: (field, params, data)=> field + ' phải nhỏ hơn hoặc bằng ' + params[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."),
      max_custom: (field, params, data)=> field + ' không thể có nhiều hơn ' + params[0] + ' ký tự'
    }
  },
  en: {
    messages: {
      verify_password: 'Password include at least 1 capital letter and number',
      password_match: 'A new password must be different from the current password',
      password_not_match: "The confirmed new password did not match",
      duplicate_values: (field, params, data)=> field + ' ' + 'must not duplicate!',
      regex_score: 'Score that is a multiple of 0.5',
      date_time_between: (field, params, data)=> 'The ' + field.toLowerCase() +' must be between ' + params[0] + ' and ' + params[1],
      required_time_poll: (field, params, data) => '',
      max_value_custom: (field, params, data)=> field + ' field must be ' + params[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' or less',
      max_custom: (field, params, data)=> 'The ' + field + ' field may not be greater than ' + params[0] + ' characters'
    }
  },
  ja: {
    messages: {
      verify_password: 'パスワードには少なくとも8文字が含まれ、少なくとも1つの大文字、1つの小文字、1つの数字が含まれます。',
      password_match: '新しいパスワードは今までのパスワードと異なるものにしてください',
      password_not_match: "パスワードが一致しません",
      duplicate_values: (field, params, data)=> field + 'は重複してはなりません！',
      regex_score: 'スコアは0.5を掛けたものです',
      date_time_between: (field, params, data)=>  `${field}は${params[0]}から${params[1]}の間でなければなりません`,
      required_time_poll: (field, params, data) => '',
      max_value_custom: (field, params, data)=> field + params[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + '以下でなければなりません',
      max_custom: (field, params, data)=> field + 'は' + params[0] + '文字以内にしてください'
    }
  },
  pt: {
    messages: {
      verify_password: 'Password include at least 1 capital letter and number',
      password_match: 'A new password must be different from the current password',
      password_not_match: "The confirmed new password did not match",
      duplicate_values: (field, params, data)=> field + ' ' + 'must not duplicate!',
      regex_score: 'Score that is a multiple of 0.5',
      date_time_between: (field, params, data)=> 'The ' + field.toLowerCase() +' must be between ' + params[0] + ' and ' + params[1],
      required_time_poll: (field, params, data) => '',
      max_value_custom: (field, params, data)=> field + ' field must be ' + params[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' or less',
      max_custom: (field, params, data)=> 'The ' + field + ' field may not be greater than ' + params[0] + ' characters'
    }
  }
});
function toASCII(chars) {
  return chars.replace(/[\uff01-\uff5e]/g, function(ch) { 
    return String.fromCharCode(ch.charCodeAt(0) - 0xfee0); 
  });
}
function isNullOrUndefined(value) {
  return value === null || value === undefined;
};
function isEmptyArray(arr) {
  return Array.isArray(arr) && arr.length === 0;
};